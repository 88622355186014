import { Pipe, PipeTransform } from '@angular/core';
/**
 * Transform the Prio of an Event to a String
 */
@Pipe({
  name: 'prioState'
})
export class PrioStatePipe implements PipeTransform {
  /**
   * Transform the number value to a String
   * @param value 
   * @returns 
   */
  transform(value: string): string {
    switch (String(value)) {
      case "0":
        return 'Standard'
      case "1000":
        return 'Top-Angebot 1'
      case "930":
        return 'Top-Angebot 2'
      case "920":
        return 'Top-Angebot 3'
      case "910":
        return 'Top-Angebot 4'
      case "900":
        return 'Top-Angebot 5'
      case "890":
        return 'Top-Angebot 6'
      case "880":
        return 'Top-Angebot 7'
      case "870":
        return 'Top-Angebot 8'
      case "860":
        return 'Top-Angebot 9'
      case "850":
        return 'Top-Angebot 10'
      case "840":
        return 'Top-Angebot 11'
      case "830":
        return 'Top-Angebot 12'
      case "820":
        return 'Top-Angebot 13'
      case "810":
        return 'Top-Angebot 14'
      case "800":
        return 'Top-Angebot 15'
      case "790":
        return 'Top-Angebot 16'
      case "780":
        return 'Top-Angebot 17'
      case "770":
        return 'Top-Angebot 18'
      case "760":
        return 'Top-Angebot 19'
      case "750":
        return 'Top-Angebot 20'
      case "740":
        return 'Top-Angebot 21'
      case "730":
        return 'Top-Angebot 22'
      case "720":
        return 'Top-Angebot 23'
      case "710":
        return 'Top-Angebot 24'
      case "700":
        return 'Top-Angebot 25'
      case "690":
        return 'Top-Angebot 26'
      case "680":
        return 'Top-Angebot 27'
      case "670":
        return 'Top-Angebot 28'
      case "660":
        return 'Top-Angebot 29'
      case "650":
        return 'Top-Angebot 30'
      default:
        return "Standard";
    }
  }
}
