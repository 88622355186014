import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { McpService } from 'src/app/services/mcp.service';
import { Mandant } from 'src/app/interfaces/mandant';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { OverlayService } from 'src/app/services/overlay.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { switchMap } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  /**
   * The Title of the APP (Shown in the Header) (set via ENV)
   */
  title: string = environment.appName;
  /**
   * The Form to let the USer login
   */
  userForm: UntypedFormGroup;
  /**
   * is the PW in cleartext
   */
  show: boolean = false;
  /**
   * Is the ClientID set via the URL => disable the select
   */
  hasClientSetPerURL: boolean = false;
  /**
   * All Configs of the Mandants
   */
  systemConfigs = [];
  /**
   * The Currcent ENV
   */
  environment = environment;
  /**
   * Is the Page loading
   */
  loading: boolean = true;

  /**
   * constructor
   * Init the Form
   * @param authenticationService 
   * @param router 
   * @param route 
   * @param fb 
   * @param mcp 
   * @param overlayService 
   */
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private mcp: McpService,
    private overlayService: OverlayService,
    private environmentService: EnvironmentService) {
    this.userForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      system: [null, Validators.required],
    });
  }
  /**
   * Get All Clients and Push to the Dropdown
   */
  ngOnInit() {
    this.mcp.getClients().subscribe(mandantMap => {
      const ids = Object.keys(mandantMap);
      let mandants: Mandant[] = ids.map((id) => {
        return { id: id, ...mandantMap[id] };
      });
      this.systemConfigs = mandants.filter(x => {
        return x['property'] == 1
      });
      this.systemConfigs.push(
        {
          id: '100',
          name: 'Demo',
          backend: 'https://demo.plusservices.de',
          property: 1
        }
      )

      this.systemConfigs.push(
        {
          id: '144',
          name: 'Stadtwerke Warendorf',
          backend: 'https://stadtwerke-warendorf.plusservices.de',
          property: 1
        }
      )

      this.systemConfigs.push(
        {
          id: '100',
          name: 'Vertrieb',
          backend: 'https://vertrieb.plusservices.de',
          property: 1
        }
      )

      this.systemConfigs.push(
        {
          id: '113',
          name: 'Lemgo Test (develop)',
          backend: 'https://develop.plusservices.de',
          property: 1
        }
      )

      if (environment.clientID != null) {
        this.userForm.controls['system'].removeValidators([Validators.required]);
        this.userForm.controls['system'].updateValueAndValidity();
      }

      if(environment.apiURL != ''){
        this.userForm.controls['system'].patchValue(
          {
            id: environment.clientID,
            name: '',
            backend: environment.apiURL,
            property: 1
          }
        )

      }

      this.route.queryParams.subscribe(params => {
        this.systemConfigs.forEach(config => {
          if (config.id == params.clientid) {
            this.userForm.patchValue({
              system: config
            }),
              this.hasClientSetPerURL = true;
          }
        });
      });
    })

    // Automatic login, probably perform at higher level like app.component
    if (this.authenticationService.currentUserValue) {
      this.routeAuthenticatedUser();
    }
  }
  /**
   * Login via PW and Username
   */
  login() {
    this.loading = true;
    this.environmentService.setClientConfig(this.userForm.value.system).pipe(
      switchMap((val: any) => {
        return this.authenticationService.login(this.userForm.value.username, this.userForm.value.password, this.userForm.value.system)
      })
    ).subscribe({
      next: () => {
        this.routeAuthenticatedUser();
      },
      error: (err: HttpErrorResponse) => {
        console.log(err)
        if (err.status == 401) {
          this.overlayService.openSnackBar('Diese Kombination aus E-Mail und Passwort ist uns leider nicht bekannt.')
        }
        this.loading = false;
      }
    })

  }
  /**
   * Route based on the route based on the Role
   */
  routeAuthenticatedUser() {
    if (this.authenticationService.isSuperAdmin()) {
      this.router.navigateByUrl('/admin/client-list');
    } else if (this.authenticationService.isAdmin()) {
      this.router.navigateByUrl('/admin');
    } else if (this.authenticationService.isSB()) {
      this.router.navigateByUrl('/caseworker');
    } else if (this.authenticationService.isPartner()) {
      this.router.navigateByUrl('/partner');
    }
  }

  lostPW() {
    this.overlayService.openLostPW("Passwort vergessen", 'Passwort zurücksetzen')
  }
}
