import { Pipe, PipeTransform } from '@angular/core';
/**
 * Transform the State of the BusinessentityFilter based on the Type
 */
@Pipe({
  name: 'translateFilterBusinessentity'
})
export class TranslateFilterBusinessentityPipe implements PipeTransform {
/**
 * Transform the state based on the Type
 * @param value 
 * @param type 
 * @returns 
 */
  transform(value: string[], type: String): string[] {
    if (type == 'filterState') {
      let returnArray = [];
      value.forEach((element) => {
        switch (element) {
          case 'ACTIVE':
            if (returnArray.length == 0) {
              returnArray.push("Aktiv");
            }
            break;
          case 'DISABLED':
            if (returnArray.length == 0) {
              returnArray.push("Archiviert");
            }
            break;
          case 'NEW':
            if (returnArray.length == 0) {
              returnArray.push("Erstellt");
            }
            break;
          case 'DELETED':
            if (returnArray.length == 0) {
              returnArray.push("Gelöscht");
            }
            break;
          case 'PENDING':
            if (returnArray.length == 0) {
              returnArray.push("Ausstehend");
            }
            break;         
          default:
            returnArray.push(element);
            break;
        }
      });
      return returnArray
    }else if (type == 'marketRoles') {
      let returnArray = [];
      value.forEach((element) => {
        switch (element) {
          case 'EVENT_PARTNER':
            if (returnArray.length == 0) {
              returnArray.push("Event Partner");
            }
            break;
          case 'SERVICE_CENTER':
            if (returnArray.length == 0) {
              returnArray.push("Service-Center");
            }
            break;
          default:
            returnArray.push(element);
            break;
        }
      });
      return returnArray
    }

  }

}
