import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BusinessuserService } from 'src/app/services/businessuser.service';
import { OverlayService } from 'src/app/services/overlay.service';

/**
 * The Data to be passed into the Dialog
 */
export interface DialogData {
  /**
   * The Title of the Dialog
   */
  title: string;
  /**
   * The message in the Dialog
   */
  message: string;
  /**
   * The okText on the Button
   */
  okText: string;
  /**
   * The Text on the Cacelbutton
   */
  cancelText: string;
  /**
   * The Id of the User to delte
   */
  publicId: string;
}


@Component({
  selector: 'app-delte-user',
  templateUrl: './delte-user.component.html',
  styleUrl: './delte-user.component.scss'
})
export class DelteUserComponent {

  constructor(
    public dialogRef: MatDialogRef<DelteUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private businessuserService:BusinessuserService,
    private overlay:OverlayService,
  ){}

  delteUser(){
    this.businessuserService.deleteAccount(this.data.publicId).subscribe({
      next:res =>{
        this.overlay.openSnackBar("Kunde erfolgreich nach DSGVO gelöscht!")
        console.log(res)
      },
      error: err =>{
        console.log(err)
        this.overlay.openSnackBar("Fehler beim Löschen");
      }
    })
  }

  /**
   * Close the Dialog
   */
  cancel(): void {
    this.dialogRef.close();
  }
}
