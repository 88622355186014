import { Pipe, PipeTransform } from '@angular/core';
/**
 * Transform the State of the Interest based on the Type
 */
@Pipe({
  name: 'translateFilterInterest'
})
export class TranslateFilterInterestPipe implements PipeTransform {
  /**
   * Transform the state based on the Type
   * @param value 
   * @param type 
   * @returns 
   */
  transform(value: string, type: string): string {
    if (type == 'filterState') {
      switch (value) {
        case 'ACTIVE':
          return "Aktiv";
        case 'DELETED':
          return "Gelöscht";
        default:
          return value;
      }

    }
  }

}
