import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AccountState } from '../enums/account-state.enum';
import { BusinessEntityResource } from '../interfaces/business-entity-resource';
import { BusinessUserResource } from '../interfaces/business-user-resource';
import { ContactChannelResource } from '../interfaces/contactchannel';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessEntityService {
  /**
   * constructor
   * @param http 
   * @param environment 
   */
  constructor(
    private http: HttpClient,
    private environment: EnvironmentService) {

  }
  /**
   * Get all BuissnesEntities paginated
   * @param page 
   * @param size 
   * @returns 
   */
  getAllBuissnesEntities(page, size) {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessentity`, { params })))
  }
  /**
   * Get BuissnesEntities by Filter
   * @param page 
   * @param size 
   * @param state 
   * @param searchTerm 
   * @returns 
   */
  getAllBuissnesEntitiesWithFilter(page, size, state, searchTerm) {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })

    const body = {
      states: state,
      searchTerm: searchTerm
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessentity/search`, body, { params })
      )
    )
  }
  /**
   * Create a new BuissnesEntities
   * @param businessentity 
   * @returns 
   */
  createBusinessentity(businessentity: BusinessEntityResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessentity`, businessentity)))
  }
  /**
   * Add a Channel to a BuissnesEntity
   * @param businessentityID 
   * @param contactChannelResource 
   * @returns 
   */
  addContactChannel(businessentityID, contactChannelResource: ContactChannelResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessentity/${businessentityID}/contactchannel`, contactChannelResource)))
  }
  /**
   * Delete  a Channel from a BuissnesEntity
   * @param businessentityID 
   * @param contactChannelResource 
   * @returns 
   */
  delteContactChannel(businessentityID, contactChannelResource: ContactChannelResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.delete(`${url}/businessentity/${businessentityID}/contactchannel/${contactChannelResource.channelType}`)
      )
    )
  }
  /**
   * Update a Channel from a BuissnesEntity
   * @param businessentityID 
   * @param contactChannelResource 
   * @returns 
   */
  updateContactChannel(businessentityID, contactChannelResource: ContactChannelResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/businessentity/${businessentityID}/contactchannel`, contactChannelResource)))
  }
  /**
   * Add a Logo to a BuissnesEntity
   * @param businessentityID 
   * @param file 
   * @returns 
   */
  addLogo(businessentityID, file) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessentity/${businessentityID}/logo`, formData)))
  }
  /**
   * Create a new Anon Member (needed to create a new User)
   * @returns 
   */
  createAnonMember() {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessuser`, null)))
  }
  /**
   * Update a Member (needed to create a new User)
   * @param member 
   * @returns 
   */
  updateAnonMember(member: BusinessUserResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessuser`, member)))
  }
  /**
   * Update a Member
   * @param member 
   * @returns 
   */
  updateMember(member: BusinessUserResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessuser`, member)))
  }
  /**
   * Delte a Member
   * @param member 
   * @returns 
   */
  deleteMember(member: BusinessUserResource) {
    member.account.state = AccountState.DELETED;
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessuser`, member)))
  }
  /**
   * Change the State of a Businessentity
   * @param businessentity 
   * @returns 
   */
  changeState(businessentity: BusinessEntityResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/businessentity/${businessentity.publicId}`, businessentity)))
  }
  /**
   * Add a Member to a Businessentity
   * @param businessentityID 
   * @param member 
   * @returns 
   */
  addMember(businessentityID, member: BusinessUserResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/businessentity/${businessentityID}/member`, member)))
  }
  /**
   * Get all Members to a Businessentity
   * @param businessentityID 
   * @returns 
   */
  getMembersToBusinessEntity(businessentityID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessentity/${businessentityID}/member`, {})
      )
    )
  }
  /**
   * Get a BusinessEntity by the ID
   * @param ID 
   * @returns 
   */
  getBusinessEntityService(ID): Observable<BusinessEntityResource> {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/businessentity/${ID}`, {})))
  }
  /**
   * Update a BusinessEntity
   * @param en 
   * @returns 
   */
  updateBusinessEntity(en: BusinessEntityResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/businessentity/${en.publicId}`, en)))
  }
  /**
   * Get the Partners as a vnd.ms-excel
   * @returns 
   */
  getPartnerList() {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/partner`, { responseType: 'blob', headers: new HttpHeaders().set('Content-Type', 'application/vnd.ms-excel') })
      ))
  }
  /**
   * Create a Member from the Adminview
   * @param member 
   * @param confirm 
   * @returns 
   */
  createMemberViaAdmin(member, confirm: boolean) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/customer/caseworker?confirm=${confirm}`, member)))
  }
  /**
   * Edit a Member from the Adminview
   * @param member 
   * @returns 
   */
  editMemberViaAdmin(member) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/customer/${member.publicId}`, member)))
  }
}
