import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VoucherService } from 'src/app/services/voucher.service';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OverlayService } from 'src/app/services/overlay.service';


/**
 * The Data to be passed into the Dialog
 */
export interface DialogData {
  /**
   * The Title of the Dialog
   */
  title: string;
  /**
   * The message in the Dialog
   */
  message: string;
  /**
   * The okText on the Button
   */
  okText: string;
  /**
   * The ID of the Event
   */
  eventID: string;
}

@Component({
  selector: 'app-edit-voucher-codes-dialog',
  templateUrl: './edit-voucher-codes-dialog.component.html',
  styleUrls: ['./edit-voucher-codes-dialog.component.scss']
})
export class EditVoucherCodesDialogComponent implements OnInit {
  /**
   * The Type of the COdes
   */
  usageType:string = "";
  /**
   * The Codes used from the  voucherKeyStrategy Multi
   */
  public usedVouchersMulti = "";
    /**
   * The Codes used from the  voucherKeyStrategy Single
   */
  public usedVouchersSingle = [];
  /**
   * The Form to add new Codes
   */
  public voucherForm: UntypedFormGroup;
/**
 * The Type of Strat to add the keys (one or multiple)
 */
  public voucherOptions = [
    { value: 'EXTERNAL_KEY_LIST', viewValue: 'Liste von einmal verwendeten Codes', state: false },
    { value: 'EXTERNAL_SINGLE_KEY', viewValue: 'Code wird wiederverwendet', state: false },
  ];
/**
 * constructor
 * Init the Form
 * @param dialogRef 
 * @param data 
 * @param voucherService 
 * @param fb 
 * @param overlayService 
 */
  constructor(
    public dialogRef: MatDialogRef<EditVoucherCodesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private voucherService: VoucherService,
    private fb: UntypedFormBuilder,
    private overlayService: OverlayService) {
    this.voucherForm = this.fb.group({
      voucherKeyStrategy: ["", [Validators.required]],
      singleVoucherKey: ["", []],
       voucherKeys: ["", []]
    })
  }
/**
 * Ge the Codes and patch to the Form
 */
  ngOnInit() {
    this.voucherService.getAllVoucherCodesToEvent(this.data.eventID).subscribe(value => {
      this.voucherForm.patchValue({
        voucherKeyStrategy: value.voucherKeyStrategy,
      })
      value.externalVoucherKeys.forEach(element => {
        if (element.usedAt == null && element.usageType == 'SINGLE_USE') {
          this.usedVouchersSingle.push(element)
        } else if (element.usedAt == null && element.usageType == 'MULTI_USE') {
          this.usedVouchersMulti = element.voucherKey;
        }
      });
      let t = this.usedVouchersSingle.map(function (k) {
        return k.voucherKey;
      }).join('\r\n');
      if (value.voucherKeyStrategy == "EXTERNAL_SINGLE_KEY") {
        this.voucherForm.controls.singleVoucherKey.setValue(this.usedVouchersMulti);
        this.voucherForm.controls.singleVoucherKey.addValidators([Validators.required])
      } else if (value.voucherKeyStrategy == "EXTERNAL_KEY_LIST") {
        this.voucherForm.controls.voucherKeys.setValue(t);
        this.voucherForm.controls.voucherKeys.addValidators([Validators.required])
      }
    })
  }
/**
 * Close the Dialog
 */
  cancel(): void {
    this.dialogRef.close();
  }
/**
 * Save the Vouchers 
 * @returns 
 */
  saveVoucherChanges() {
    this.voucherForm.markAllAsTouched();
    if (this.voucherForm.invalid) {
      return
    }
    if (this.voucherForm.value.voucherKeyStrategy == "EXTERNAL_SINGLE_KEY") {
      let t = {
        "sequence": null,
        "usedAt": null,
        "usageType": "MULTI_USE",
        "voucherKey": this.voucherForm.value.singleVoucherKey
      }

      this.voucherService.saveVoucherCodes(this.data.eventID, t, this.voucherForm.value.voucherKeyStrategy).subscribe(value => {
        if (value == null) {
          this.overlayService.openSnackBar("Externe Gutschein-Codes wurden gespeichert!");
          this.dialogRef.close();
        }
      })
    } else {
      var keyList = this.voucherForm.value.voucherKeys;
      let temp = keyList.split('\n')
      let t = [];
      temp.forEach(element => {
        t.push({
          "sequence": null,
          "usedAt": null,
          "usageType": "SINGLE_USE",
          "voucherKey": element
        })
      });

      this.voucherService.saveVoucherCodes(this.data.eventID, t, this.voucherForm.value.voucherKeyStrategy).subscribe(value => {
        if (value == null) {
          this.overlayService.openSnackBar("Externe Gutschein-Codes wurden gespeichert!");
          this.dialogRef.close();
        }
      })
    }
  }
/**
 * Change the Type and add the matching Validators
 */
  changeType() {
    if (this.voucherForm.value.voucherKeyStrategy == "EXTERNAL_SINGLE_KEY") {
      this.voucherForm.controls.singleVoucherKey.addValidators([Validators.required]);
      this.voucherForm.controls.voucherKeys.clearValidators();
    } else if (this.voucherForm.value.voucherKeyStrategy == "EXTERNAL_KEY_LIST") {
      this.voucherForm.controls.voucherKeys.addValidators([Validators.required])
      this.voucherForm.controls.singleVoucherKey.clearValidators();
    }
  }
}
