import { Component, Inject } from '@angular/core';
import { DialogComponent, DialogData } from '../dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountResource } from 'src/app/interfaces/account-resource';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { OverlayService } from 'src/app/services/overlay.service';

@Component({
  selector: 'app-lost-pw',
  templateUrl: './lost-pw.component.html',
  styleUrl: './lost-pw.component.scss'
})
export class LostPWComponent {
  resetPWForm: FormGroup;
  public account: AccountResource = {
    username: '',
  }

  /**
   * constructor
   * @param dialogRef 
   * @param data 
   */
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private auth: AuthenticationService,
    private overlayService: OverlayService) {

    this.resetPWForm = this.fb.group({
      userName: ["", [Validators.required]],
    });
  }
  /**
   * ngOnInit
   */
  ngOnInit() {
  }
  /**
   * Close the Dialog
   */
  cancel(): void {
    this.dialogRef.close();
  }


  submitForm() {
    this.resetPWForm.markAllAsTouched();
    this.resetPWForm.markAsDirty();
    if (this.resetPWForm.invalid)
      return
    this.account.username = this.resetPWForm.value.userName;
    this.auth.doPasswordLost(this.account).subscribe({
      next: res => {
        this.overlayService.openSnackBar('Passwort erfolgreich zurückgesetzt! Bitte überprüfen Sie Ihre E-Mails');
        this.resetPWForm.reset();
      },
      error: err => {
        console.log(err)
        if (err['exception'] == 'gates.commons.exception.ResourceNotFoundException') {
          this.overlayService.openSnackBar('Benutzer nicht gefunden', 'error');
        } else {
          this.overlayService.openSnackBar(err?.error?.message, 'error');
        }

      }
    })
  }
}
