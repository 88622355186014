import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { EnvironmentService } from './environment.service';
/**
 * The Service to get the External Codes for an Event
 */
@Injectable({
  providedIn: 'root'
})
export class VoucherService {
  /**
   * The constructor for the VoucherService
   * @param http 
   * @param environment 
   */
  constructor(
    private http: HttpClient,
    private environment: EnvironmentService) {

  }
  /**
   * Get all the Code to an Event
   * @param publicid 
   * @returns 
   */
  getAllVoucherCodesToEvent(publicid): Observable<any> {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/event/${publicid}/external-voucher-key-configuration`)
      )
    )
  }
  /**
   * Save the external Codes for an Event
   * @param publicid 
   * @param VoucherUpdate 
   * @param type 
   * @returns 
   */

  saveVoucherCodes(publicid: string, VoucherUpdate, type): Observable<any> {
    const body = {
      voucherKeyStrategy: type,
      externalVoucherKeys: VoucherUpdate
    }

    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/event/${publicid}/external-voucher-key-configuration`, body)
      )
    )
  }
}
