<h3 mat-dialog-title>{{ data.title }}</h3>
<div mat-dialog-content>
  <div class="row">
    <div class=" col-lg-12 ">
      <p>{{heading}}</p>
    </div>
  </div>
  <form [formGroup]="reOpenAccountForm">

    <div class="row">
      <div class=" col-lg-12 ">
        <mat-form-field class="full-width">
          <mat-label>Name:</mat-label>
          <input matInput type="text" formControlName="username">
          <mat-error *ngIf="reOpenAccountForm.get('username').hasError('required')">
            Name <strong>muss</strong> angegeben werden
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class=" col-lg-12 ">
        <mat-form-field class="full-width">
          <mat-label>vorläufiges Passwort:</mat-label>
          <!-- <input matInput type="text" formControlName="oldPassword"> -->

          <input matInput placeholder="altes Passwort"   [type]="showOldPassword ? 'text' : 'password'" formControlName="oldPassword">
          <button mat-icon-button matSuffix (click)="   this.showOldPassword = !this.showOldPassword;"  [attr.aria-label]="'Hide password'" [attr.aria-pressed]="showOldPassword">
            <mat-icon >{{showOldPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>


          <mat-error *ngIf="reOpenAccountForm.get('oldPassword').hasError('required')">
            Vorläufiges Passwort <strong>muss</strong> angegeben werden
          </mat-error>

          
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class=" col-lg-12 ">
        <mat-form-field class="full-width">
          <mat-label>neues Passwort:</mat-label>
          <!-- <input matInput type="text" formControlName="password"> -->


          <input matInput placeholder="neues Passwort"   [type]="showNewPassword ? 'text' : 'password'" formControlName="password">
          <button mat-icon-button matSuffix (click)="   this.showNewPassword = !this.showNewPassword;"  [attr.aria-label]="'Hide password'" [attr.aria-pressed]="showNewPassword">
            <mat-icon >{{showNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="reOpenAccountForm.get('password').hasError('required')">
            neues Passwort <strong>muss</strong> angegeben werden
          </mat-error>

          <mat-error *ngIf="reOpenAccountForm.get('password').hasError('minlength')">
            neues Passwort <strong>muss mindestens</strong> 6 Zeichen lang sein
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>


</div>

<div mat-dialog-actions align="end">
  <button mat-button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial
    (click)="setPassword()">{{ data.okText }}</button>
</div>