import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VoucherService } from 'src/app/services/voucher.service';
/**
 * The Data to be passed into the Dialog
 */
interface DialogData {
  /**
   * The Title of the Dialog
   */
  title: string;
  /**
   * The message in the Dialog
   */
  message: string;
  /**
   * The okText on the Button
   */
  okText: string;
  /**
   * The ID of the Event
   */
  eventID: string;
}

/**
 * THe Component to show the VoucherCodes that are used
 */
@Component({
  selector: 'app-show-voucher-codes',
  templateUrl: './show-voucher-codes.component.html',
  styleUrls: ['./show-voucher-codes.component.scss']
})
export class ShowVoucherCodesComponent implements OnInit {
  /**
   * usedVouchers as an Array
   */
  usedVouchers = [];
  /**
   * constructor
   * @param dialogRef 
   * @param data 
   * @param voucherService 
   */
  constructor(
    public dialogRef: MatDialogRef<ShowVoucherCodesComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private voucherService:VoucherService) {}

    /**
     * ngOnInit
     * Get all Codes to the Event passed in (The codes have to be used)
     */
  ngOnInit() {
    this.voucherService.getAllVoucherCodesToEvent(this.data.eventID).subscribe(value => {
      value.externalVoucherKeys.forEach(element => {
        if (element.usedAt != null) {
          this.usedVouchers.push(element)
        }
      });
    })
  }
/**
 * CLose the Dialog
 */
  cancel(): void {
    this.dialogRef.close();
  }
}
