import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { BusinessEntityResource } from 'src/app/interfaces/business-entity-resource';
import { BusinessEntityService } from 'src/app/services/business-entity.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { ChangeEventStateComponent } from '../change-event-state/change-event-state.component';
/**
 * The Data to be passed into the Dialog
 */
interface DialogData {
  /**
   * BusinessEntityResource
   */
  businessEntityResource: BusinessEntityResource;
}

@Component({
  selector: 'app-change-business-entity-state',
  templateUrl: './change-business-entity-state.component.html',
  styleUrls: ['./change-business-entity-state.component.scss']
})
export class ChangeBusinessEntityStateComponent implements OnInit {
  /**
   * The States of the BusinessEntity
   */
  list = [
    { value: "ACTIVE", viewValue: "Aktiv" },
    // { value: "DISABLED", viewValue: "Archiviert" },
    // { value: "NEW", viewValue: "Erstellt" },
    { value: "DELETED", viewValue: "Gelöscht" },
    // { value: "PENDING", viewValue: "Ausstehend" }  
  ]
  /**
   * constructor
   * @param businessEntityService 
   * @param data 
   * @param dialogRef 
   * @param overlayService 
   */
  constructor(
    private businessEntityService: BusinessEntityService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: DialogData,
    public dialogRef: MatBottomSheetRef<ChangeEventStateComponent>,
    private overlayService: OverlayService) { }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
  }
  /**
   * Update the State of the BusinessEntity
   * @param e 
   */
  changeState(e) {
    this.data.businessEntityResource.state = e
    this.businessEntityService.changeState(this.data.businessEntityResource).subscribe((value: BusinessEntityResource) => {
      if (value.publicId != null) {
        this.overlayService.openSnackBar('Status erfolgreich geändert!');
        this.dialogRef.dismiss();
      }
    })
  }
}