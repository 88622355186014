import { Location } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

/**
 * The Data to be passed into the Dialog
 */
export interface DialogData {
  /**
 * The Title of the Dialog
 */
  title: string;
  /**
   * The okText on the Button
   */
  okText: string;
  /**
   * The messages in the Dialog
   */
  messages:Array<String>
}


@Component({
  selector: 'app-erp-sync-messages',
  templateUrl: './erp-sync-messages.component.html',
  styleUrl: './erp-sync-messages.component.scss'
})
export class ErpSyncMessagesComponent {
/**
 * constructor
 * @param dialogRef 
 * @param data 
 * @param _location 
 */
constructor(public dialogRef: MatDialogRef<ErpSyncMessagesComponent>,
  @Inject(MAT_DIALOG_DATA) public data: DialogData,
  private _location: Location
) { }
/**
* ngOnInit
*/
ngOnInit() {
}
/**
* Close the Dialog
*/
cancel(): void {
  this.dialogRef.close(false);
}
/**
* move back one Page
*/
moveToOverview() {
  this.dialogRef.close(true);
}
}
