import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EventResource } from '../interfaces/event-resource';
import { Interest } from '../interfaces/interest'
import { PagedResources } from '../interfaces/paged-resources';
import { EventReservationRequest } from '../interfaces/event-reservation-request';
import { BusinessUserResource } from '../interfaces/business-user-resource';
/**
 * The Servie to create/edit/delte Events
 */
@Injectable({
  providedIn: 'root'
})
export class EventService {
  /**
   * The Count of the Events ('ACTIVE', 'HIDDEN)
   */
  eventCount$: Subject<number> = new ReplaySubject(1);
  /**
 * The Count of all Events
 */
  alleventCount$: Subject<number> = new ReplaySubject(1);
  /**
   * constructor
   * set eventCount
   * set alleventCount
   * @param http 
   * @param environment 
   */
  constructor(
    private http: HttpClient,
    private environment: EnvironmentService) {
    this.searchForEvents(0, 1, ['ACTIVE', 'HIDDEN'], null, null, null).subscribe((count: PagedResources<EventResource>) => {
      this.eventCount$.next(count.page.totalElements)
    })

    this.searchForEvents(0, 999, ['ACTIVE', 'HIDDEN', 'ARCHIVED', 'EXPIRED'], null, null, null).subscribe((count: PagedResources<EventResource>) => {
      this.alleventCount$.next(count.page.totalElements)
    })
  }
  /**
   * Get an Event by ID (Event V2)
   * @param publicid 
   * @returns 
   */
  getEvent(publicid: string): Observable<any> {
    return this.environment.apiV2$.pipe(
      switchMap(url => this.http.get(`${url}/event/${publicid}`)
      )
    )
  }
  /**
   * Get an EVENT by ID (Event V1)
   * @param publicid 
   * @returns 
   */
  getEventV1(publicid: string): Observable<any> {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/event/${publicid}`)
      )
    )
  }
  /**
   * Get all Events Paginated
   * @param page 
   * @param size 
   * @returns 
   */
  getEvents(page: number, size: number): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/event`, { params }))
    )
  }
  /**
   * Disable an Event
   * @param event 
   * @returns 
   */
  disableEvent(event) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/event`, event))
    )
  }
  /**
   * Merge the Events by Event V1 and V2 (to get all Properties)
   * @param page 
   * @param size 
   * @param state 
   * @param type 
   * @param partners 
   * @param term 
   * @returns 
   */
  mergeEventsFromV1andV2(page: number, size: number, state, type, partners, term) {
    return combineLatest([
      this.searchForEvents(page, size, state, type, partners, term),
      this.searchForEventsV2(page, size, state, type, partners, term),
    ]).pipe(
      map(([v1, v2]) => {
        let l = {
          page: v1['page'],
          content: v1['content'].map((item, i) => Object.assign({}, item, v2['content'][i])),
          links: v1['links'],
        }
        return l;
      })
    )
  }
  /**
   * Get One Event by ID and merge the Event by Event V1 and V2 (to get all Properties)
   * @param publicid 
   * @returns 
   */
  mergeOneEventFromV1andV2(publicid: string): Observable<any> {
    return combineLatest([
      this.getEvent(publicid),
      this.getEventV1(publicid),
    ]).pipe(
      map(([v1, v2]) => {
        return Object.assign(v1, v2);
      })
    )
  }

  searchForEvents(page: number, size: number, state, type, partners, term) {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      filterByStates: state,
      filterByDiscountType: type,
      filterByPartners: partners,
      searchTerm: term
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/search`, body, { params }))
    )
  }
  /**
   * Search fot an Event (V2)
   * @param page 
   * @param size 
   * @param state 
   * @param type 
   * @param partners 
   * @param term 
   * @returns 
   */
  searchForEventsV2(page: number, size: number, state, type, partners, term) {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      filterByStates: state,
      filterByDiscountType: type,
      filterByPartners: partners,
      searchTerm: term
    }
    return this.environment.apiV2$.pipe(
      switchMap(url => this.http.post(`${url}/event/search`, body, { params }))
    )
  }
  /**
   * Get all Interests
   * @returns 
   */
  getInterests() {
    const body = {
      filterByState: "ACTIVE"
    }

    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/interest/search`, body, {}).pipe(
      ))
    )
  }
  /**
   * Get all Partners
   * @returns 
   */
  getPartners() {
    return this.http
      .get<any>(`${this.environment.getApiUrl()}/event/partner?page=${0}&size=${999}`, {});
  }
  /**
   * Create an Event
   * @param event 
   * @returns 
   */
  createEvent(event: EventResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event`, event))
    )
  }
  /**
   * Edit an Event
   * @param event 
   * @returns 
   */
  editEvent(event: EventResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/event`, event)
      )
    )
  }
  /**
   * Add an Interest to an Event
   * @param eventID 
   * @param interest 
   * @returns 
   */
  addInterestToEvent(eventID, interest: Interest) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/${eventID}/interest`, interest, {}))
    )
  }
  /**
   * Remove an Interest to an Event
   * @param eventID 
   * @param interestID 
   * @returns 
   */
  removeInterestFromEvent(eventID, interestID: number) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.delete(`${url}/event/${eventID}/interest/${interestID}`, {})
      )
    )
  }
  /**
   * Get the Interests to an Event 
   * @param eventID 
   * @returns 
   */
  getInterestsForEvent(eventID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/event/${eventID}/interest`, {})
      )
    )
  }
  /**
   * Change the State of an Event
   * @param event 
   * @returns 
   */
  changeState(event) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/event`, event))
    )
  }
  /**
   * Get the Top Three Events by totalActiveReservationsCount
   * @param state 
   * @param type 
   * @param partners 
   * @param term 
   * @returns 
   */
  getTopThreeEvents(state, type, partners, term) {
    const params = new HttpParams({
      fromObject: {
        page: 0,
        size: 999
      }
    })

    const body = {
      filterByStates: state,
      filterByDiscountType: type,
      filterByPartners: partners,
      searchTerm: term
    }

    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/search`, body, { params }).pipe(
        map((res: any) => {
          let records = res.content;
          return records.sort((a, b) => {
            return b.totalActiveReservationsCount - a.totalActiveReservationsCount;
          });
        })
      )
      )
    )
  }

  /**
   * Get the Count of the Reservations to an Event
   * @param page 
   * @param size 
   * @param state 
   * @param eventPublicId 
   * @returns 
   */
  getReservationCountToEventID(page, size, state, eventPublicId): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      filterByStates: state,
      eventPublicId: eventPublicId
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/attendance/search`, body, { params }))
    )
  }
  /**
   * Get all Reservations to an Event
   * @param page 
   * @param size 
   * @param state 
   * @param eventPublicId 
   * @returns 
   */
  getAllReservationsToEventID(page, size, state, eventPublicId): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      filterByStates: state,
      eventPublicId: eventPublicId
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/attendance/search`, body, { params }).pipe(
        map(((rallies: any) => rallies.content.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())))
      ))
    )
  }
  /**
   * Get all Hidden Events for the Campaigns
   * @param page 
   * @param size 
   * @returns 
   */
  getEventsForCampaigns(page, size) {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size
      }
    })
    const body = {
      filterByStates: ["HIDDEN"],

    }
    return this.environment.apiV2$.pipe(
      switchMap(url => this.http.post(`${url}/event/search`, body, { params })
      )
    )
  }
  /**
   * Search for the Event Reservations via Event V2
   * @param page 
   * @param size 
   * @param sort 
   * @param state 
   * @param paymentState 
   * @param partners 
   * @param term 
   * @param event 
   * @param eventStates 
   * @param customerPublicIds 
   * @returns 
   */
  searchForEventReservations(page: number, size: number, sort, state, paymentState, partners, term, event, eventStates, customerPublicIds?) {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size,
        sort: sort
      }
    })
    const body = {
      filterByStates: state,
      filterByPaymentStates: paymentState,
      eventPartnerPublicIds: partners,
      searchTerm: term,
      eventPublicId: event,
      eventStates: eventStates,
      customerPublicIds: customerPublicIds
    }
    return this.environment.apiV2$.pipe(
      switchMap(url => this.http.post(`${url}/event/reservation/search`, body, { params }))
    )
  }
  /**
   * Search for the Event Reservations via Event V1
   * @param page 
   * @param size 
   * @param sort 
   * @param state 
   * @param paymentState 
   * @param partners 
   * @param term 
   * @param event 
   * @param eventStates 
   * @param customerPublicIds 
   * @returns 
   */
  searchForEventReservationsV1(page: number, size: number, sort, state, paymentState, partners, term, event, eventStates, customerPublicIds?) {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size,
        sort: sort
      }
    })
    const body = {
      filterByStates: state,
      filterByPaymentStates: paymentState,
      eventPartnerPublicIds: partners,
      searchTerm: term,
      eventPublicId: event,
      eventStates: eventStates,
      customerPublicIds: customerPublicIds
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/reservation/search`, body, { params }))
    )
  }
  /**
   * Get the ReservationList for an Event
   * @param page 
   * @param size 
   * @param eventID 
   * @returns 
   */
  getReservationListForEvent(page: number, size: number, eventID) {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size,
      }
    })
    const body = {
      eventPublicId: eventID
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/reservation/search`, body, { params }))
    )
  }
  /**
   * Get the Reservations Grouped
   * @param eventID 
   * @returns 
   */
  getReservationListForEventGrouped(eventID) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/event/${eventID}/reservation/grouped`))
    )
  }
  /**
   * Get the Events by eventID
   * @param page 
   * @param size 
   * @param eventID 
   * @param filterByStates 
   * @returns 
   */
  getReservationListForEventGroupBy(page: number, size: number, eventID, filterByStates): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: page,
        size: size,
      }
    })
    const body = {
      eventPublicId: eventID,
      filterByStates: filterByStates
    }

    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/reservation/search`, body, { params }))
    )
  }
  /**
   * Get the Voucher PDF to a Reservation
   * @param reservationID 
   * @returns 
   */
  getPDF(reservationID) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/reservation/${reservationID}/voucher/pdf`, { headers: headers, responseType: 'blob' }))
    )
  }
  /**
   * delete a Reservation
   * @param resID 
   * @returns 
   */
  delteReservation(resID) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    const body = '"CANCELLED"'
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/reservation/${resID}/state`, body, options))
    )
  }
  /**
   * Delete an allready used Reservation
   * @param resID 
   * @returns 
   */
  delteAlreadyUsesReservation(resID) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    const body = '"RESERVED"'
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/reservation/${resID}/state`, body, options))
    )
  }
  /**
   * Set the Reservation to USED
   * @param resID 
   * @returns 
   */
  useReservation(resID) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    const body = '"USED"'
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/reservation/${resID}/state`, body, options))
    )
  }
  /**
   * Create a new Reservation
   * @param user 
   * @param event 
   * @param emailNotify 
   * @param force 
   * @returns 
   */
  createReservation(user: BusinessUserResource, event: EventResource, emailNotify, force) {
    const body: EventReservationRequest = {
      customerPublicId: user.publicId,
      eventPublicId: event.publicId,
      force: force
    }
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/reservation/check-and-reserve?emailNotify=${emailNotify}`, body))
    )
  }
  /**
   * Create a quickAttendance for the Event via the Code
   * @param eventID 
   * @param code 
   * @param forceAttendance 
   * @returns 
   */
  quickAttendance(eventID: String, code: String, forceAttendance) {
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    const body = code;
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/${eventID}/quickAttendance?forceAttendance=${forceAttendance}`, body, options))
    )
  }
  /**
   * Bulkupdate all Events
   * @param dryrun 
   * @returns 
   */
  bulkUpdateEvents(dryrun) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post(`${url}/event/bulkupdate?dryRun=${dryrun}`, {}))
    )
  }
  /**
   * Get the QR Code to a Event as a PNG
   * @param event 
   * @returns 
   */
  getQRCodeAsPNG(event: EventResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/event/${event.publicId}/qr`, { responseType: 'blob' })
      )
    )
  }
  /**
   * Get the Reservations to an Event as a application/vnd.ms-excel
   * @param event 
   * @returns 
   */
  downloadReservationList(event) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/event/${event.publicId}/reservation`, { responseType: 'blob', headers: new HttpHeaders().set('Content-Type', 'application/vnd.ms-excel') })
      )
    )
  }
  /**
   * Get the Advaned Event Properties
   * @param eventID 
   * @returns 
   */
  loadAdvancedEventProperties(eventID: string) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/event/${eventID}/advanced`)
      )
    )
  }
  /**
   * save the Advaned Event Properties
   * @param event 
   * @returns 
   */
  saveAdvancedEventProperties(event: EventResource) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.put(`${url}/event/${event.publicId}/advanced`, event)
      )
    )
  }
}
