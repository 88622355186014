<h3 mat-dialog-title>{{ data.title }}</h3>
<div mat-dialog-content>
    <p *ngFor="let message of this.data.messages">
        {{ message}}
    </p>
</div>

<div mat-dialog-actions align="end">
    <button mat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial (click)="moveToOverview()">{{
        data.okText }}</button>
</div>