import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CardResource } from 'src/app/interfaces/card-resouce';
import { CardService } from 'src/app/services/card.service';
/**
 * The Data to be passed into the Dialog
 */
interface DialogData {
  /**
   * The Title of the Dialog
   */
  title: string;
  /**
   * The message in the Dialog
   */
  message: string;
  /**
   * The okText on the Button
   */
  okText: string;
  /**
   * The Card
   */
  card: CardResource;
}
/**
 * The Component to show the CARD QR Code
 */
@Component({
  selector: 'app-show-card-qr-code',
  templateUrl: './show-card-qr-code.component.html',
  styleUrls: ['./show-card-qr-code.component.scss']
})
export class ShowCardQrCodeComponent implements OnInit {
  /**
   * The QR-Code URL
   */
  qr: SafeUrl = ''
/**
 * constructor
 * @param dialogRef 
 * @param data 
 * @param cardSerive 
 * @param sanitizer 
 */
  constructor(
    public dialogRef: MatDialogRef<ShowCardQrCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private cardSerive:CardService,
    private sanitizer: DomSanitizer,
  ) { }
/**
 * Get the URL TO the CARD QR Code
 */
  ngOnInit(): void {
    this.cardSerive.getCardQRCode(this.data.card).subscribe(val=>{
      let objectURL = URL.createObjectURL(val);
      this.qr = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    })
  }
/**
 * Close the Dialog
 */
  cancel(): void {
    this.dialogRef.close();
  }
}