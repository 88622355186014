<h3 mat-dialog-title>{{ data.title }}</h3>
<div mat-dialog-content>
    <div class="flex-vertival-container outer-padding-horizontal margin-between-vertical-childs">
        <form [formGroup]="resetPWForm">
            <mat-form-field class="full-width" appearance="fill">
                <mat-label>Benutzername</mat-label>
                <input matInput formControlName="userName" placeholder="max@mustermann.de">
            </mat-form-field>
        </form>
    </div>
</div>

<div mat-dialog-actions align="end">
    <button mat-button (click)="cancel()">Abbrechen</button>
    <button mat-button mat-raised-button color="primary" cdkFocusInitial (click)="submitForm()">{{ data.okText
        }}</button>
</div>