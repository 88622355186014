import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
/**
 * The Data to be passed into the Dialog
 */
export interface DialogData {
  /**
   * The Title of the Dialog
   */
  title: string;
  /**
   * The message in the Dialog
   */
  message: string;
  /**
   * The okText on the Button
   */
  okText: string;
  /**
   * The Text on the cancel Button
   */
  cancelText: string;
}
/**
 * The Dialog to show the message and let the user confirm the Deletion
 */
@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements OnInit {
/**
 * constructor
 * @param dialogRef 
 * @param data 
 */
  constructor(
    public dialogRef: MatDialogRef<DeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {
  }
/**
 * Close the Dialog and emit false
 */
  cancel(): void {
    this.dialogRef.close(false);
  }
  /**
   * Close the Dialog and emit true
   */
  continue() {
    this.dialogRef.close(true);
  }
}
