import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessUserResource } from 'src/app/interfaces/business-user-resource';
import { BusinessuserService } from 'src/app/services/businessuser.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { ConfirmedValidator } from 'src/app/Validators/ConfirmedValidator';
/**
 * The Data to be passed into the Dialog
 */

interface DialogData {
  /**
   * The Title of the Dialog
   */
  title: string;
  /**
   * The message in the Dialog
   */
  message: string;
  /**
   * The okText on the Button
   */
  okText: string;
  /**
   * BusinessUserResource
   */
  user: BusinessUserResource;
}
/**
 * The Component to allow the SB to reset the PW for the User
 */
@Component({
  selector: 'app-reset-pwfor-user',
  templateUrl: './reset-pwfor-user.component.html',
  styleUrls: ['./reset-pwfor-user.component.scss']
})
export class ResetPWForUserComponent implements OnInit {
  /**
   * the Form to reset the PW
   */
  pwForm: UntypedFormGroup;
  /**
   * Is the PW cleartext
   */
  hidePW: boolean = true;
  /**
 * Is the PW confirm cleartext
 */
  hidePWConfirm: boolean = true;
  /**
   * constructor
   * init the Form
   * @param data 
   * @param dialogRef 
   * @param fb 
   * @param businessuserService 
   * @param overlayService 
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ResetPWForUserComponent>,
    private fb: UntypedFormBuilder,
    private businessuserService: BusinessuserService,
    private overlayService: OverlayService) {
    this.pwForm = this.fb.group({
      newPassword: ["", [Validators.required, Validators.minLength(6)]],
      passwordRecitation: ["", [Validators.required, Validators.minLength(6)]]
    }, {
      validator: ConfirmedValidator('newPassword', 'passwordRecitation')
    })
  }
/**
 * ngOnInit
 */
  ngOnInit(): void {
  }
/**
 * Set the PW for the User
 * @returns 
 */
  setPW() {
    this.pwForm.markAllAsTouched();
    if (this.pwForm.invalid) {
      return;
    }
    this.businessuserService.setPassword(this.data.user, this.pwForm.value.newPassword, this.pwForm.value.passwordRecitation).subscribe(fromSetPW => {
      if (fromSetPW) {
        this.overlayService.openSnackBar("Der Nutzer kann sich mit dem vorläufigem Passwort anmelden, muss es nach erstmaliger Anmeldung jedoch wieder ändern.");
        this.dialogRef.close();
      }
    })
  }
/**
 * Close the Dialog
 */
  cancel(): void {
    this.dialogRef.close();
  }
}
