import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { ContractResource } from '../interfaces/contract-resource';
import { PagedResources } from '../interfaces/paged-resources';
/**
 * The Service used for rhe Contracts set by the Admin
 */
@Injectable({
  providedIn: 'root'
})
export class ContractService {
  /**
   * Has the Customer an Adventcalendar
   */
  has_adventCalender$: BehaviorSubject<string | boolean> = new BehaviorSubject(null);
  /**
   * The Intro Landingpages
   */
  component_web_intro_landing_page$: ReplaySubject<string | boolean> = new ReplaySubject(null);
  /**
   * The Events to the Advencalendar
   */
  hasAdvancedEventDetails$: ReplaySubject<string | boolean> = new ReplaySubject(null);
  /**
   * constructor
   * @param http 
   * @param environment 
   */
  constructor(
    private http: HttpClient,
    private environment: EnvironmentService) {
    this.getContract();
  }
  /**
   * Get the paginated Contracts
   * @param page 
   * @param size 
   * @returns 
   */
  getContracts(page = 0, size = 300) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get<any[]>(`${url}/contract?page=${page}&size=${size}`).pipe(
        map(res => res)
      )
      )
    )
  }
  /**
   * Disable a Contract
   * @param servicePublicId 
   * @returns 
   */
  disableContract(servicePublicId) {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.delete(`${url}/contract/${servicePublicId}`)
      )
    )
  }
  /**
   * Enable or Update a Contract
   * @param servicePublicId 
   * @param value 
   * @returns 
   */
  enableOrUpdateContract(servicePublicId, value) {
    return this.http.post(`${this.environment.getApiUrl()}/contract`, {
      servicePublicId,
      value
    });
  }
  /**
   * Get all Contracts
   * @returns 
   */
  getContract() {
    const params = new HttpParams({
      fromObject: {
        page: '0',
        size: '999'
      }
    })

    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get<PagedResources<ContractResource>>(`${url}/contract`, { params }).pipe(
        map((pagedResource: PagedResources<ContractResource>) => pagedResource.content)
      )
      )
    ).subscribe((contracts: ContractResource[]) => {
      this.setValue(contracts, 'event/advent', this.has_adventCalender$);
      this.setValueReplay(contracts, 'component_web_intro_landing_page', this.component_web_intro_landing_page$);
      this.setValueReplay(contracts, 'caseworker/event/v2/advanced/{eventId}', this.hasAdvancedEventDetails$)
    })
  }

  /**
   * Set the Value of a Contract
   * @param contracts 
   * @param path 
   * @param saveTo$ 
   */
  private setValue(contracts: ContractResource[], path: string, saveTo$: BehaviorSubject<any>) {
    let contract: ContractResource = contracts.filter(contract => contract['path'] == path)[0]
    if (contract?.value) {
      saveTo$.next(contract.value)
    } else if (contract && contract.value == null) {
      saveTo$.next(true)
    } else {
      saveTo$.next(false)
    }
  }
  /**
   * Set the Value of a Contract
   * @param contracts 
   * @param path 
   * @param saveTo$ 
   */
  private setValueReplay(contracts: ContractResource[], path: string, saveTo$: ReplaySubject<any>) {
    let contract: ContractResource = contracts.filter(contract => contract['path'] == path)[0]
    if (contract?.value) {
      saveTo$.next(contract.value)
    } else if (contract && contract.value == null) {
      saveTo$.next(true)
    } else {
      saveTo$.next(false)
    }
  }
  /**
   * Update the intro Landingpages
   * @param value 
   * @returns 
   */
  updateComponent_web_intro_landing_page(value) {
    const body = {
      path: "component_web_intro_landing_page",
      value: value
    }
    return this.http.post(`${this.environment.getApiUrl()}/contract`, body);
  }
  /**
   * Create the Advertisingbox
   * @param activeBoxes 
   * @param TextRef 
   * @param ImageRef 
   * @param State 
   * @param alligment 
   * @returns 
   */
  createAdvertisingBox(activeBoxes, TextRef, ImageRef, State, alligment) {
    activeBoxes.push(JSON.parse('["' + TextRef + '","' + ImageRef + '","' + State + '","' + alligment + '"]'))

    const body = {
      "path": "component_web_intro_landing_page",
      "value": JSON.stringify(activeBoxes)
    }

    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.post<any[]>(`${url}/contract`, body)
      )
    )
  }
}
