import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
/**
 * The Data to be passed into the Dialog
 */
export interface DialogData {
  /**
   * The Title of the Dialog
   */
  title: string;
  /**
   * The Message of the Dialog
   */
  message: string;
  /**
   * The Text on the ok Button
   */
  okText: string;
}
/**
 * Dialog to show a Message
 */
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  /**
   * constructor
   * @param dialogRef 
   * @param data 
   */
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

  }
  /**
   * ngOnInit
   */
  ngOnInit() {
  }
  /**
   * Close the Dialog
   */
  cancel(): void {
    this.dialogRef.close();
  }
}
