import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessUserResource } from 'src/app/interfaces/business-user-resource';
import { BusinessEntityService } from 'src/app/services/business-entity.service';
import { ContractService } from 'src/app/services/contract.service';

/**
 * The Data to be passed into the Dialog
 */
export interface DialogData {
  /**
   * The Title of the Dialog
   */
  title: string;
  /**
   * The message in the Dialog
   */
  message: string;
  /**
   * The okText on the Button
   */
  okText: string;
  /**
   * The Text on the Cacelbutton
   */
  cancelText: string;
  /**
   * object
   */
  object;
  /**
   * type
   */
  type: string;
}

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {
  /**
   * constructor
   * @param dialogRef 
   * @param data 
   * @param businessEntityService 
   * @param contractService 
   */
  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private businessEntityService: BusinessEntityService,
    private contractService: ContractService
  ) { }
  /**
   * ngOnInit
   */
  ngOnInit() {
  }
  /**
   * Delete the Type of Data passed in
   */
  delete() {
    switch (this.data.type) {
      case 'channel':
        this.businessEntityService.delteContactChannel(this.data.object.currentBusinessEntity, this.data.object.channel).subscribe(value => {
        })
        break;
      case 'members':
        this.businessEntityService.deleteMember(this.data.object).subscribe(deleteRes => {
        })
        break;
      case 'openingHours':

        break;
      case 'boxes':
        let all = [];
        all = this.data.object.all;
        let toDelete = this.data.object.toDelete;
        all.forEach((element, index) => {
          if (element[0] == toDelete[0]) {
            all.splice(index, 1);
          }
        });
        this.contractService.updateComponent_web_intro_landing_page(JSON.stringify(all)).subscribe(deleteRes => {

        });
        break;
      default:
        break;
    }
  }
  /**
   * Close the Dialog
   */
  cancel(): void {
    this.dialogRef.close();
  }
}
