import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { BusinessUserResource } from 'src/app/interfaces/business-user-resource';
import { BusinessuserService } from 'src/app/services/businessuser.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { ChangeEventStateComponent } from '../change-event-state/change-event-state.component';
/**
 * The Data to be passed into the Dialog
 */
interface DialogData {
  /**
   * businessUserResource
   */
  businessUserResource: BusinessUserResource;
}
/**
 * The Component to change the State of a User
 */
@Component({
  selector: 'app-change-business-user-state',
  templateUrl: './change-business-user-state.component.html',
  styleUrls: ['./change-business-user-state.component.scss']
})
export class ChangeBusinessUserStateComponent implements OnInit {
  /**
   * The State a User can have
   */
  public list = [
    { value: "NEW", viewValue: "Neu" },
    { value: "PENDING", viewValue: "Wartend" },
    { value: "REJECTED", viewValue: "Abgelehnt" },
    { value: "ACTIVE", viewValue: "Aktiv" },
    { value: "DISABLE_REQUEST", viewValue: "Deaktivierung beantragt" },
    { value: "DISABLED", viewValue: "Deaktiviert" },
    { value: "DELETED", viewValue: "Gelöscht" }
  ]
  /**
   * constructor
   * @param businessuserService 
   * @param data 
   * @param dialogRef 
   * @param overlayService 
   */
  constructor(
    private businessuserService: BusinessuserService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: DialogData,
    public dialogRef: MatBottomSheetRef<ChangeEventStateComponent>,
    private overlayService: OverlayService) {

  }
  /**
   * ngOnInit
   */
  ngOnInit(): void {

  }
  /**
   * Change the State of the User
   * @param e 
   */
  changeState(e) {
    this.businessuserService.changeState(this.data.businessUserResource, e).subscribe((value: BusinessUserResource) => {
      if (value.publicId != null) {
        this.overlayService.openSnackBar('Status erfolgreich geändert!');
        this.dialogRef.dismiss();
      }
    })
  }
}
