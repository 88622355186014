import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { EventResource } from 'src/app/interfaces/event-resource';
import { EventService } from 'src/app/services/event.service';
/**
 * The Data to be passed into the Dialog
 */
interface DialogData {
  /**
   * The Title of the Dialog
   */
  title: string;
  /**
   * The message in the Dialog
   */
  message: string;
  /**
   * The okText on the Button
   */
  okText: string;
  /**
   * The EventResource
   */
  event: EventResource;
}

/**
 * THe Component to show the QR Code 
 */
@Component({
  selector: 'app-show-qr-code',
  templateUrl: './show-qr-code.component.html',
  styleUrls: ['./show-qr-code.component.scss']
})
export class ShowQrCodeComponent implements OnInit {
  /**
   * The QR-Code URL
   */
  qr: SafeUrl = ''
/**
 * constructor
 * @param dialogRef 
 * @param data 
 * @param eventService 
 * @param sanitizer 
 */
  constructor(
    public dialogRef: MatDialogRef<ShowQrCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private eventService:EventService,
    private sanitizer: DomSanitizer,
  ) { }
/**
 * ngOnInit
 * Get the QR-Code UR to the event passed in
 */
  ngOnInit(): void {
    this.eventService.getQRCodeAsPNG(this.data.event).subscribe((val:any)=>{
      let objectURL = URL.createObjectURL(val);
      this.qr = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    })
  }
  /**
   * Close the Dialog
   */
  cancel(): void {
    this.dialogRef.close();
  }
}
