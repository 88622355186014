import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { Location } from '@angular/common';
/**
 * The Data to be passed into the Dialog
 */
export interface DialogData {
  /**
 * The Title of the Dialog
 */
  title: string;
  /**
   * The message in the Dialog
   */
  message: string;
  /**
   * The okText on the Button
   */
  okText: string;
}

/**
 * The Dialog to show the Error passed in 
 */
@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {
/**
 * constructor
 * @param dialogRef 
 * @param data 
 * @param _location 
 */
  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _location: Location
  ) { }
/**
 * ngOnInit
 */
  ngOnInit() {
  }
/**
 * Close the Dialog
 */
  cancel(): void {
    this.dialogRef.close();
  }
/**
 * move back one Page
 */
  moveToOverview() {
    this._location.back();
  }
}
