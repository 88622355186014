import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReOpenAccountRequest } from 'src/app/interfaces/reopen-account-request';

import { take } from 'rxjs';
import { BusinessuserService } from 'src/app/services/businessuser.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from 'src/app/interfaces/user';
/**
 * The Data to be passed into the Dialog
 */
export interface DialogData {
  /**
   * The Title of the Dialog
   */
  title: string;
  /**
   * The message in the Dialog
   */
  message: string;
  /**
   * The okText on the Button
   */
  okText: string;
  /**
   * User
   */
  user: User;
}


@Component({
  selector: 'app-reopen-account',
  templateUrl: './reopen-account.component.html',
  styleUrls: ['./reopen-account.component.scss'],
})
export class ReopenAccountComponent implements OnInit {

  public reOpenAccountRequest: ReOpenAccountRequest = {
    username: '',
    password: '',
    oldPassword: ''
  }

  public heading: string = "Sie können sich mit dem vorläufigem Passwort anmelden, es muss nach erstmaliger Anmeldung jedoch wieder geändert werden.";
  public showOldPassword: boolean = false;
  public showNewPassword: boolean = false;
  public showNewPasswordConfirm: boolean = false;
  public reOpenAccountForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private businessuserService: BusinessuserService,
    private overlayService: OverlayService,
    public dialogRef: MatDialogRef<ReopenAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {

    this.reOpenAccountForm = this.fb.group({
      username: [{ value: this.data.user.account.username, disabled: true }, [Validators.required],],
      oldPassword: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    })
  }

  ngOnInit() {

  }

  /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  private markFormGroupDirty(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsDirty();
      control.markAllAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupDirty(control)
      }
    });
  }



  setPassword() {
    this.reOpenAccountForm.markAllAsTouched();
    if (this.reOpenAccountForm.invalid) {
      return;
    }
    this.markFormGroupDirty(this.reOpenAccountForm);
    this.reOpenAccountRequest.username = this.reOpenAccountForm.getRawValue().username;
    this.reOpenAccountRequest.oldPassword = this.reOpenAccountForm.value.oldPassword;
    this.reOpenAccountRequest.password = this.reOpenAccountForm.value.password;
    this.businessuserService.reOpenAccount(this.reOpenAccountRequest).pipe(take(1)).subscribe({
      next: res => {
        this.overlayService.openSnackBar("Passwort erfolgreich geändert!");
        this.dialogRef.close();
      },
      error: err => {
        this.overlayService.openSnackBar(err.error.message);
      }
    })
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
